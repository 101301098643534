import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IModalOptions } from '@mt-ng2/modal-module';

@Component({
    selector: 'app-denied-modal',
    template: `
        <!-- denial modal -->
        <div *ngIf="denialType != 5">
            <modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
                <div class="tweb-modal">
                    <h2>Sorry!</h2>
                    <div class="text-left small">
                        <p>
                            Thank you for your interest in becoming an egg donor. We regret to inform you that, based on your medical history, you do
                            not qualify to become an egg donor at this time. Please apply again in one year and feel free to contact us with any
                            questions.
                            <br /><br />
                            TWESB Phone/Email:<br />
                            +1-877-331-2427<br />
                            Arizona +1-602-678-1906<br />

                            Donor@twesb.com
                        </p>
                    </div>
                    <button type="button" class="button button--primary" (click)="cancel()">Ok</button>
                </div>
            </modal-wrapper>
        </div>
        <div *ngIf="denialType == 5">
            <modal-wrapper [options]="modalOptions" (cancelClick)="cancel()">
                <div class="tweb-modal">
                    <h2>Duplicate Profile</h2>
                    <div class="text-left small">
                        <p>
                            Thank you for your interest in becoming an egg donor. An application already exists on file with the provided social
                            security number. Please contact our administrative team with any questions.
                            <br /><br />
                            TWESB Phone/Email:<br />
                            +1-877-331-2427<br />
                            Arizona +1-602-678-1906<br />

                            Donor@twesb.com
                        </p>
                    </div>
                    <button type="button" class="button button--primary" (click)="cancel()">Ok</button>
                </div>
            </modal-wrapper>
        </div>
    `,
})
export class DeniedModalComponent {
    modalOptions: IModalOptions = {
        allowOutsideClick: true,
        showConfirmButton: false,
        width: '75%',
    };
    @Input() denialType: number;
    @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    cancel(): void {
        this.onCancel.emit();
    }
}
