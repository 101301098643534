import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { debounceTime } from 'rxjs/operators';
import { ApplicationService, ICurrentSectionAndStatus } from '../../services/application.service';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';
import { ApplicationWhoAreYouDynamicControlsPartial } from '@model/partials/application-who-are-you-partial.form-controls';
import { ApplicationWhoAreYouService } from '../../services/application-who-are-you.service';
import { BirthControlTypes } from '@model/enums/birth-control-types.enum';
import { MultiselectItem } from '@mt-ng2/multiselect-control';
import { IAncestry } from '@model/interfaces/ancestry';
import { AncestryTypes } from '@model/enums/ancestry-type.enum';
import assignFormValues from '../../../common/assign-form-values.library';
import { FormSections } from '@model/enums/form-sections.enum';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { IMetaItem } from '@mt-ng2/base-service';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { IApplicationWhoAreYouChildren } from '@model/interfaces/application-who-are-you-children';
import { GenderService } from '../../../../admin-portal/recipients/outcomes/service/gender.service';
import { EducationCompletedTypes } from '@model/enums/education-completed-types.enum';

@Component({
    selector: 'who-are-you',
    templateUrl: './who-are-you.component.html',
})
export class WhoAreYouComponent implements OnInit, OnDestroy {
    @Input() whoAreYou: IApplicationWhoAreYou;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() applicationId: number;
    @Input() isWhoAreYouComplete: Subject<boolean>;
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();

    lastTwentyYearsArray: IMetaItem[] = this.getLastTwentyYearsArray();
    sexOptions: IMetaItem[] = [];
    breastFeedingOptions: IMetaItem[] = [
        { Id: 0, Name: 'Y' },
        { Id: 1, Name: 'N' },
    ];
    showIsBreastFeed = {};
    childrenDetail = {};

    // abstract controls
    abstractApplicationWhoAreYouControls: any;
    whoAreYouForm: FormGroup;
    metaData: IPersonalInformationMetaData;
    doubleClickIsDisabled = false;
    formCreated = false;
    motherAncestries: MultiselectItem[];
    fatherAncestries: MultiselectItem[];
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: 'tab-familyHistory',
        nextTabLabel: 'Family History',
        routerLink: null,
        sectionId: FormSections.WhoAreYou,
        status: '',
    };

    showBirthControlTimespan = false;

    subs = new Subscription();
    isSaved: boolean;

    get fatherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    get motherAncestrySelected(): boolean {
        return this.fatherAncestries.some((itm) => itm.Selected);
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private applicationWhoAreYouService: ApplicationWhoAreYouService,
        private applicationService: ApplicationService,
        private genderService: GenderService,
        private elementRef: ElementRef,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.whoAreYouForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        this.setShowIsBreastFeed();
        this.setChildrenDetail();
        forkJoin([this.applicationService.getPersonalInformationMetaData(), this.genderService.getAll()]).subscribe(([metaData, sexOptions]) => {
            this.metaData = metaData;
            this.motherAncestries = metaData.Ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.MotherAncestries.some((anc) => anc.Id === a.Id),
                    ),
            );
            this.fatherAncestries = metaData.Ancestries.map(
                (a) =>
                    new MultiselectItem(
                        a,
                        this.whoAreYou.FatherAncestries.some((anc) => anc.Id === a.Id),
                    ),
            );
            this.createForm();
            this.currentStatus.status = this.whoAreYou.Submitted ? 'Submitted' : (this.whoAreYou.Complete ? 'Complete' : 'In Progress');
            this.applicationService.currentSectionAndStatus.next(this.currentStatus);
            this.sexOptions = [...sexOptions];
            this.initializeApplicationChildren();
            if (this.whoAreYou.Complete) {
                this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
            }
        });
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((saved) => {
                this.isSaved = saved;
                if (saved && this.whoAreYouForm) {
                    markAllFormFieldsAsTouched(this.whoAreYouForm);
                }
            }),
        );
        this.isWhoAreYouComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationsService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.whoAreYou.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationsService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    initializeApplicationChildren(): void {
        if (this.whoAreYou.ApplicationWhoAreYouChildrens.length) {
            this.whoAreYou.ApplicationWhoAreYouChildrens.forEach((c) => Object.assign(this.childrenDetail[c.ChildId], c));
            for (let index = 0; index < this.whoAreYou.ApplicationWhoAreYouChildrens.length; index++) {
                let childDetails = this.getChildDetail(index);

                let sexOptionsId = this.sexOptions.find((s) => s.Id === childDetails.Sex).Id;
                let childYearOfBirth = childDetails.YearOfBirth.toString();
                let yearOfBirthOptionId = this.lastTwentyYearsArray.find((y) => y.Name === childYearOfBirth).Id;

                let isChildBreastFeed = childDetails.IsBreastFeed ? 'Y' : 'N';
                let isBreastFeedOptionId = this.breastFeedingOptions.find((b) => b.Name === isChildBreastFeed).Id;

                if (!this.isChildYoungerThatFive(childDetails.YearOfBirth)) {
                    this.showIsBreastFeed[index] = false;
                    childDetails.IsBreastFeed = false;
                }

                this.addApplicationChildControl(yearOfBirthOptionId, sexOptionsId, isBreastFeedOptionId);
            }
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    get otherBirthControlSelected(): boolean {
        const birthControlType = this.whoAreYouForm.get('ApplicationWhoAreYou.BirthControlTypeId');
        return birthControlType ? birthControlType.value === BirthControlTypes.Other : false;
    }

    getHasOtherAncestry(type: string): boolean {
        const control = this.whoAreYouForm.get(`ApplicationWhoAreYou.${type}Ancestries`);
        return control ? control.value.some((a: IAncestry) => a.Id === AncestryTypes.Other) : false;
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.whoAreYouForm.get(`ApplicationWhoAreYou.${controlName}`);
        return control && control.value === 1;
    }

    createForm(): void {
        this.showBirthControlTimespan = this.whoAreYou.BirthControlTypeId === BirthControlTypes.None ? false : true;
        this.getControls();
        this.whoAreYouForm = this.assignFormGroups();
        this.formCreated = true;
        this.currentStatus.formGroup = this.whoAreYouForm;
        if (this.denied) {
            setTimeout(() => this.whoAreYouForm.disable());
        } else {
            this.subs.add(
                this.whoAreYouForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.whoAreYouForm.dirty) {
                        this.assignFormValues();
                    }

                    if (this.whoAreYouForm.valid) {
                        const control = this.whoAreYouForm.get('ApplicationWhoAreYou.Submitted');
                        if (control && control.value) {
                            this.currentStatus.status = 'Submitted';
                            this.whoAreYou.Submitted = true;
                        } else {
                            this.currentStatus.status = 'Complete';
                            this.whoAreYou.Complete = true;
                            this.whoAreYou.Submitted = false;
                        }
                    } else {
                        this.currentStatus.status = 'In Progress';
                        this.whoAreYou.Complete = false;
                    }
                    this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                }),
            );
        }
    }

    //
    // Determine if the education level completed includes at least some high school.
    // Used to control wether or not the High School GPA field is displayed.
    //
    highSchoolOrAbove(): boolean {
        return this.hasRequiredEducationCompletedType(EducationCompletedTypes.SomeHighSchool);
    }

    //
    // Determine if the education level completed includes at least some college.
    // Used to control wether or not the College GPA field is displayed.
    //
    collegeOrAbove(): boolean {
        return this.hasRequiredEducationCompletedType(EducationCompletedTypes.SomeSchoolUniversity);
    }

    //
    // Determine if the education level completed includes at least the specified level.
    //
    // NOTE: Eight Grade or Below was added after the other entries and ended up with an ID
    // # that is higher than the existing entries - so it must be explicity tested for.
    //
    hasRequiredEducationCompletedType(minRequiredValue: EducationCompletedTypes): boolean {
        let hasRequiredEducation = false;
        const control = this.whoAreYouForm.get(`ApplicationWhoAreYou.EducationCompletedTypeId`);
        if (control && control.value) {
            if (control.value >= minRequiredValue && control.value !== EducationCompletedTypes.EigthGradeOrAbove) {
                hasRequiredEducation = true;
            }

        }
        return hasRequiredEducation;
    }
    getControls(): void {
        this.abstractApplicationWhoAreYouControls = new ApplicationWhoAreYouDynamicControlsPartial(this.metaData, this.whoAreYou).Form;
    }

    fatherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').markAsDirty();
        this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').markAsTouched();
    }

    motherAncestriesChanged(evt): void {
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').setValue(evt.selectedItems);
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').markAsDirty();
        this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').markAsTouched();
    }

    onBirthControlTypeValueChange(value: number): void {
        this.showBirthControlTimespan = value === BirthControlTypes.None ? false : true;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationWhoAreYou: this.fb.group({
                FatherAncestries: this.fb.control(
                    this.fatherAncestries.filter((fa) => fa.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
                MotherAncestries: this.fb.control(
                    this.motherAncestries.filter((ma) => ma.Selected).map((i) => i.Item),
                    [Validators.required],
                ),
            }),
            ApplicationWhoAreYouChildrenArray: this.fb.array([]),
        });
    }

    assignFormValues(): void {
        assignFormValues(this.whoAreYou, this.whoAreYouForm.get('ApplicationWhoAreYou').value);
        this.whoAreYou.IsCurrentlyListedOnAnotherEggDonorRoster =
            this.whoAreYouForm.get('ApplicationWhoAreYou.IsCurrentlyListedOnAnotherEggDonorRoster').value === 1;
        this.whoAreYou.HasPreviouslyDonatedEggs = this.whoAreYouForm.get('ApplicationWhoAreYou.HasPreviouslyDonatedEggs').value === 1;
        this.whoAreYou.HasGivenBirth = this.whoAreYouForm.get('ApplicationWhoAreYou.HasGivenBirth').value === 1;
        this.whoAreYou.HasBeenPregnant = this.whoAreYouForm.get('ApplicationWhoAreYou.HasBeenPregnant').value === 1;
        this.whoAreYou.HasPlasticSurgery = this.whoAreYouForm.get('ApplicationWhoAreYou.HasPlasticSurgery').value === 1;
        this.whoAreYou.HasBeenVictimOfSeriousTraumaticEvent =
            this.whoAreYouForm.get('ApplicationWhoAreYou.HasBeenVictimOfSeriousTraumaticEvent').value === 1;
        this.whoAreYou.HasDepression = this.whoAreYouForm.get('ApplicationWhoAreYou.HasDepression').value === 1;
        this.whoAreYou.HasMoodSwings = this.whoAreYouForm.get('ApplicationWhoAreYou.HasMoodSwings').value === 1;
        this.whoAreYou.HasAnxiety = this.whoAreYouForm.get('ApplicationWhoAreYou.HasAnxiety').value === 1;
        this.whoAreYou.HasSchizophreniaSigns = this.whoAreYouForm.get('ApplicationWhoAreYou.HasSchizophreniaSigns').value === 1;
        this.whoAreYou.HasBeenUnderPsychiatristCare = this.whoAreYouForm.get('ApplicationWhoAreYou.HasBeenUnderPsychiatristCare').value === 1;
        this.whoAreYou.HasBeenInRehab = this.whoAreYouForm.get('ApplicationWhoAreYou.HasBeenInRehab').value === 1;
        this.whoAreYou.HasFamilyBirthDefects = this.whoAreYouForm.get('ApplicationWhoAreYou.HasFamilyBirthDefects').value === 1;
        this.whoAreYou.FatherAncestries = this.whoAreYouForm.get('ApplicationWhoAreYou.FatherAncestries').value;
        this.whoAreYou.MotherAncestries = this.whoAreYouForm.get('ApplicationWhoAreYou.MotherAncestries').value;
        this.whoAreYou.ApplicationWhoAreYouChildrens = [...this.getChildrenDetail()];
        if (!this.showBirthControlTimespan) {
            this.whoAreYou.BirthControlTimespan = '';
        }
    }

    getChildrenDetail(): IApplicationWhoAreYouChildren[] {
        let result: IApplicationWhoAreYouChildren[] = [];
        for (const key in this.childrenDetail) {
            if (Object.prototype.hasOwnProperty.call(this.childrenDetail, key)) {
                const element = this.childrenDetail[key];
                result.push(element);
            }
        }
        return result;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    get ApplicationWhoAreYou(): FormGroup {
        return this.whoAreYouForm.get('ApplicationWhoAreYou') as FormGroup;
    }

    get ApplicationWhoAreYouChildrenArray(): FormArray {
        return this.whoAreYouForm.get('ApplicationWhoAreYouChildrenArray') as FormArray;
    }

    get ApplicationWhoAreYouChildrenFormGroup(): FormGroup[] {
        let group = [];
        for (const iterator of this.ApplicationWhoAreYouChildrenArray.controls) {
            group.push(iterator as FormGroup);
        }
        return group;
    }

    getApplicationChildControlLength(): number {
        return this.ApplicationWhoAreYouChildrenArray.controls.length;
    }

    clearApplicationChildControl(): void {
        this.ApplicationWhoAreYouChildrenArray.controls = [];
    }

    clearApplicationChildControlPop(): void {
        this.ApplicationWhoAreYouChildrenArray.controls.pop();
    }

    addApplicationChildControl(yearOfBirthOptionId = 0, sexOptionsId = 1, isBreastFeedOptionId = 0): void {
        this.ApplicationWhoAreYouChildrenArray.push(
            this.getApplicationWhoAreYouChildrenFormGroup(yearOfBirthOptionId, sexOptionsId, isBreastFeedOptionId),
        );
    }

    getApplicationWhoAreYouChildrenFormGroup(yearOfBirthOptionId: number, sexOptionsId: number, isBreastFeedOptionId: number): FormGroup {
        return this.fb.group({
            ChildGender: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Sex',
                name: 'ChildGender',
                options: this.sexOptions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: sexOptionsId !== undefined && sexOptionsId !== null ? sexOptionsId : 1,
            }),
            IsBreastFeed: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Are You Breastfeeding',
                name: 'IsBreastFeed',
                options: this.breastFeedingOptions,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: isBreastFeedOptionId !== undefined && isBreastFeedOptionId !== null ? isBreastFeedOptionId : 0,
            }),
            YearOfChildBirth: new DynamicField({
                formGroup: 'ApplicationWhoAreYouChildrenArray',
                label: 'Year Of Birth',
                name: 'YearOfChildBirth',
                options: this.lastTwentyYearsArray,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [Validators.required],
                validators: { required: true },
                value: yearOfBirthOptionId !== undefined && yearOfBirthOptionId !== null ? yearOfBirthOptionId : 0,
            }),
        });
    }

    onHowManyLiveBirthsValueChanges(liveBirthsValue: number): void {
        let numberOfApplicationChildControls = this.getApplicationChildControlLength();
        let indexStart = 0;
        if (numberOfApplicationChildControls === 0 || liveBirthsValue === 0) {
            this.clearApplicationChildControl();
        }

        if (liveBirthsValue < numberOfApplicationChildControls) {
            for (let index = liveBirthsValue; index < numberOfApplicationChildControls; index++) {
                this.clearApplicationChildControlPop();
                delete this.childrenDetail[index];
            }
            return;
        }

        if (liveBirthsValue > numberOfApplicationChildControls) {
            indexStart = numberOfApplicationChildControls;
        }

        for (let index = indexStart; index < liveBirthsValue; index++) {
            this.addApplicationChildControl();
            this.childrenDetail[index] = this.getEmptyApplicationWhoAreYouChildren(index);
            this.onYearOfChildBirthValueChange(0, index);
        }
    }

    onYearOfChildBirthValueChange(yearId: number, childIndex: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(childIndex);
        let year = parseInt(this.lastTwentyYearsArray.filter((i) => i.Id === yearId)[0].Name, 10);
        childDetail.YearOfBirth = year;

        if (this.isChildYoungerThatFive(year)) {
            this.showIsBreastFeed[childIndex] = true;
            return;
        }

        this.showIsBreastFeed[childIndex] = false;
        childDetail.IsBreastFeed = false;
    }

    onChildGenderValueChange(value: number, index: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(index);
        childDetail.Sex = this.sexOptions.filter((s) => s.Id === value)[0].Id;
    }

    onIsBreastFeedValueChange(value: number, index: number): void {
        this.ApplicationWhoAreYou.markAsDirty();
        let childDetail = this.getChildDetail(index);
        let breastFeedingValue = this.breastFeedingOptions.filter((b) => b.Id === value)[0].Name;
        childDetail.IsBreastFeed = breastFeedingValue === 'Y' ? true : false;
    }

    setShowIsBreastFeed(): void {
        if (this.whoAreYou?.HowManyLiveBirths) {
            for (let index = 0; index < this.whoAreYou.HowManyLiveBirths; index++) {
                this.showIsBreastFeed[index] = true;
            }
        }
    }

    setChildrenDetail(): void {
        if (this.whoAreYou?.HowManyLiveBirths) {
            for (let index = 0; index < this.whoAreYou.HowManyLiveBirths; index++) {
                this.childrenDetail[index] = this.getEmptyApplicationWhoAreYouChildren(index);
            }
        }
    }

    getChildDetail(index: number): IApplicationWhoAreYouChildren {
        return this.childrenDetail[index];
    }

    getEmptyApplicationWhoAreYouChildren(index: number): IApplicationWhoAreYouChildren {
        return {
            ApplicationWhoAreYousId: this.whoAreYou.Id,
            ChildId: index,
            DateCreated: new Date(),
            Id: 0,
            IsBreastFeed: true,
            Sex: 1,
            YearOfBirth: new Date().getFullYear(),
        };
    }

    getLastTwentyYearsArray(): IMetaItem[] {
        let currentYear = new Date().getFullYear();
        let result: IMetaItem[] = [];
        for (let index = 0; index <= 20; index++) {
            result.push({ Id: index, Name: (currentYear - index).toString() });
        }
        return result;
    }

    isChildYoungerThatFive(childBirthYear: number): boolean {
        let fiveYearsAgo = new Date().getFullYear() - 4;
        if (childBirthYear > fiveYearsAgo) {
            return true;
        }
        return false;
    }

    get isComplete(): boolean {
        return this.whoAreYou.Complete;
    }
}
