import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { DonorApplicationComponent } from './donor-application.component';
import { DonorApplicationRoutingModule } from './donor-application-routing.module';
import { BasicsCompleteComponent } from './sections/basics-complete/basics-complete.component';
import { PreliminaryHistoryComponent } from './sections/preliminary-history/preliminary-history.component';
import { MedicalIssuesComponent } from './sections/medical-issues/medical-issues.component';
import { WhoAreYouComponent } from './sections/who-are-you/who-are-you.component';
import { IssueSelectorComponent } from './sections/medical-issues/issue-selector/issue-selector.component';
import { StatusIndicatorComponent } from './status-indicator/status-indicator.component';
import { ModalModule } from '@mt-ng2/modal-module';
import { FamilyHistoryComponent } from './sections/family-history/family-history.component';
import { ApplicationSubmitComponent } from './sections/application-submit/application-submit.component';
import { IndividualFamilyHistoryComponent } from './sections/family-history/individual-family-history-component/individual-family-history.component';

@NgModule({
    declarations: [
        DonorApplicationComponent,
        BasicsCompleteComponent,
        PreliminaryHistoryComponent,
        MedicalIssuesComponent,
        WhoAreYouComponent,
        IssueSelectorComponent,
        StatusIndicatorComponent,
        ApplicationSubmitComponent,
        IndividualFamilyHistoryComponent,
        FamilyHistoryComponent,
    ],
    exports: [DonorApplicationComponent],
    imports: [ModalModule, DonorApplicationRoutingModule, SharedModule],
})
export class DonorApplicationModule {
    static forRoot(): any {
        return {
            ngModule: DonorApplicationModule,
            providers: [],
        };
    }
}
