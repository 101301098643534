import { FamilyHistoryDynamicControls, IFamilyHistoryDynamicControlsParameters } from '../form-controls/family-history.form-controls';
import { IFamilyHistory } from '../interfaces/family-history';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, DynamicLabel, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';
import { YesNo } from '@model/enums/yes-no.enum';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';

export enum HalfSiblingTypes {
    Mother = 1,
    Father,
}

export interface IFamilyHistoryDynamicControlsParametersExtends extends IFamilyHistoryDynamicControlsParameters {
    countries?: ICountryMetaItem[];
}

export class FamilyHistoryDynamicControlsPartial extends FamilyHistoryDynamicControls {
    constructor(familyhistoryPartial?: IFamilyHistory, additionalParameters?: IFamilyHistoryDynamicControlsParametersExtends) {
        super(familyhistoryPartial, additionalParameters);

        (<DynamicLabel>this.View.PlaceOfBirth).value = familyhistoryPartial?.PlaceOfBirth
            ? additionalParameters.countries.find((c) => c.CountryCode === familyhistoryPartial?.PlaceOfBirth).Name
            : null;

        (<DynamicField>this.Form.PlaceOfBirth) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Place of birth',
            name: 'PlaceOfBirth',
            options: additionalParameters.countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                familyhistoryPartial?.PlaceOfBirth && additionalParameters.countries
                    ? additionalParameters.countries.find((c) => c.CountryCode === familyhistoryPartial?.PlaceOfBirth).Id
                    : null,
        });

        (<DynamicField>this.Form.Deceased).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.Deceased).setRequired(true);
        (<DynamicField>this.Form.Deceased).value =
            familyhistoryPartial.Deceased === null || familyhistoryPartial.Deceased === undefined
                ? null
                : familyhistoryPartial.Deceased
                ? YesNo.Yes
                : YesNo.No;
        (<DynamicField>this.Form.Deceased).options = [
            { Id: 1, Name: 'Yes', True: true },
            { Id: 2, Name: 'No', True: false },
        ];

        (<DynamicField>this.Form.CauseOfDeath).label = '';

        (<DynamicField>this.Form.IsFullSibling).type.fieldType = DynamicFieldTypes.Select;
        (<DynamicField>this.Form.IsFullSibling).setRequired(true);
        (<DynamicField>this.Form.IsFullSibling).value =
            familyhistoryPartial.IsFullSibling === null || familyhistoryPartial.IsFullSibling === undefined
                ? null
                : familyhistoryPartial.IsFullSibling
                ? YesNo.Yes
                : YesNo.No;
        (<DynamicField>this.Form.IsFullSibling).options = [
            { Id: 1, Name: 'Full Sibling', True: true },
            { Id: 2, Name: 'Half Sibling', True: false },
        ];

        (<DynamicField>this.Form.HalfSiblingType) = new DynamicField({
            formGroup: this.formGroup,
            label: '',
            name: 'HalfSiblingType',
            options: [
                { Id: HalfSiblingTypes.Mother, Name: 'Mother' },
                { Id: HalfSiblingTypes.Father, Name: 'Father' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                (familyhistoryPartial.IsHalfSiblingMother === null || familyhistoryPartial.IsHalfSiblingMother === undefined) &&
                (familyhistoryPartial.IsHalfSiblingFather === null || familyhistoryPartial.IsHalfSiblingFather === undefined)
                    ? null
                    : familyhistoryPartial.IsHalfSiblingMother
                    ? HalfSiblingTypes.Mother
                    : HalfSiblingTypes.Father,
        });

        (<DynamicField>this.Form.AgeAtDeath).label = '';
        (<DynamicField>this.Form.AgeAtDeath).placeholder = 'Age';
        (<DynamicField>this.Form.Age).setRequired(true);
        (<DynamicField>this.Form.Age).placeholder = 'Age';

        (<DynamicField>this.Form.SelectedWeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'SelectedWeightUnit',
            options: additionalParameters.weightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: familyhistoryPartial.WeightUnitId ? familyhistoryPartial.WeightUnitId : null,
        });

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            placeholder: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: familyhistoryPartial.Height ? Math.floor(familyhistoryPartial.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            placeholder: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: familyhistoryPartial.Height ? familyhistoryPartial.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            placeholder: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: familyhistoryPartial.Height ? familyhistoryPartial.Height : null,
        });

        (<DynamicField>this.Form.SelectedHeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height',
            name: 'SelectedHeightUnit',
            options: additionalParameters.heightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: familyhistoryPartial.HeightUnitId ? familyhistoryPartial.HeightUnitId : null,
        });

        (<DynamicField>this.Form.Weight).type.fieldType = DynamicFieldTypes.Numeric;
        (<DynamicField>this.Form.Weight).type.inputType = NumericInputTypes.Integer;
        (<DynamicField>this.Form.Weight).placeholder = 'Weight';
        (<DynamicField>this.Form.Weight).setRequired(true);

        (<DynamicField>this.Form.EducationCompletedTypeId).label = 'Highest Level of Education Completed';
        (<DynamicField>this.Form.EducationCompletedTypeId).setRequired(true);
        (<DynamicField>this.Form.EyeColorId).setRequired(true);
        (<DynamicField>this.Form.HairColorId).setRequired(true);
        (<DynamicField>this.Form.SkinTypeId).setRequired(true);
        (<DynamicField>this.Form.HairTextureId).setRequired(true);
        (<DynamicField>this.Form.RaceId).setRequired(true);

        (<DynamicField>this.Form.Occupation).placeholder = 'Occupation';
        (<DynamicField>this.Form.Occupation).validation = [Validators.required, Validators.minLength(1), Validators.maxLength(25)];
        (<DynamicField>this.Form.Occupation).validators = { required: true, minlength: 1, maxlength: 25 };

        (<DynamicField>this.Form.Skills).placeholder = 'Skills';
        (<DynamicField>this.Form.Skills).validation = [Validators.required, Validators.minLength(1), Validators.maxLength(100)];
        (<DynamicField>this.Form.Skills).validators = { required: true, minlength: 1, maxlength: 100 };
    }
}
