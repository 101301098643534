import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { debounceTime } from 'rxjs/operators';
import { ApplicationService, ICurrentSectionAndStatus } from '../../services/application.service';
import assignFormValues from '../../../common/assign-form-values.library';
import { FormSections } from '@model/enums/form-sections.enum';
import { IApplicationFamilyHistory } from '@model/interfaces/application-family-history';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { safeDetectChanges } from '../../../common/safe-detect-changes.library';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { FamilyHistoryService } from '../../services/family-history.service';
import { HalfSiblingTypes } from '@model/partials/family-history-partial.form-controls';
import { YesNo } from '@model/enums/yes-no.enum';
import { ApplicationFamilyHistoryDynamicControls } from '@model/form-controls/application-family-history.form-controls';
import { IFamilyHistory } from '@model/interfaces/family-history';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'family-history',
    templateUrl: './family-history.component.html',
})
export class FamilyHistoryComponent implements OnInit, OnDestroy {
    @Input() familyHistory: IApplicationFamilyHistory;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() applicationId: number;
    @Input() isFamilyHistoryComplete: Subject<boolean>;
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();

    // abstract controls
    abstractFamilyHistoryControls: any;

    familyHistoryForm: FormGroup;
    metaData: IPersonalInformationMetaData;
    formCreated = false;
    formSubscription: Subscription;
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: null,
        nextTabLabel: 'Submit Application',
        routerLink: null,
        sectionId: FormSections.FamilyHistory,
        status: '',
    };
    unitsOfMeasurement: IUnitsOfMeasurement[];
    weightUnits;
    heightUnits;
    hasSiblings: boolean;
    siblingForms: FormArray;
    siblingsToAddNumber: number;
    countries: ICountryMetaItem[] = [];

    formsReadyMap = {
        FatherHistory: false,
        MaternalGrandfatherHistory: false,
        MaternalGrandmotherHistory: false,
        MotherHistory: false,
        PaternalGrandfatherHistory: false,
        PaternalGrandmotherHistory: false,
    };
    get allFormsReady(): boolean {
        let ready = false;
        for (let form in this.formsReadyMap) {
            if (this.formsReadyMap.hasOwnProperty(form)) {
                ready = this.formsReadyMap[form];
            }
        }
        return ready;
    }
    constructor(
        private fb: FormBuilder,
        private applicationService: ApplicationService,
        private cdr: ChangeDetectorRef,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private familyHistoryService: FamilyHistoryService,
        private elementRef: ElementRef,
        private notificationService: NotificationsService,
        private commonService: CommonService,
    ) { }

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.familyHistoryForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([
            this.applicationService.getPersonalInformationMetaData(),
            this.unitsOfMeasurementService.getItems(),
            this.commonService.getCountries(),
        ]).subscribe(([metaData, units]) => {
            this.metaData = metaData;
            this.unitsOfMeasurement = units;
            this.countries = this.commonService.getCountryMetaItems();
            this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
            this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
            this.currentStatus.status = this.familyHistory ? 'Submitted' : (this.familyHistory.Complete ? 'Complete' : 'In Progress');
            this.currentStatus.routerLink = ['application', 'submit'];
            this.applicationService.currentSectionAndStatus.next(this.currentStatus);
            this.createForm();

            if (this.familyHistory.Complete) {
                this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
            }
        });
        this.hasSiblings = this.familyHistory.SiblingFamilyHistories && this.familyHistory.SiblingFamilyHistories.length > 0;
        this.isFamilyHistoryComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.familyHistory.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    setFormGroup(form: FormGroup, type: string): void {
        this.familyHistoryForm.addControl(type, form);
        this.formsReadyMap[type] = true;
        safeDetectChanges(this.cdr);
    }

    addSiblingFormToFormArray(form: FormGroup): void {
        this.siblingForms.push(form);
        safeDetectChanges(this.cdr);
    }

    ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

    optionIsSelected(controlName: string): boolean {
        const control = this.familyHistoryForm.get(`ApplicationFamilyHistory.${controlName}`);
        return control && control.value === YesNo.Yes;
    }

    createForm(): void {
        this.getControls();
        this.familyHistoryForm = this.assignFormGroups();
        this.siblingForms = this.fb.array([]);
        this.familyHistoryForm.addControl('SiblingFamilyHistories', this.siblingForms);
        this.formCreated = true;
        this.currentStatus.formGroup = this.familyHistoryForm;
        safeDetectChanges(this.cdr);
        if (this.denied) {
            setTimeout(() => this.familyHistoryForm.disable());
        } else {
            this.formSubscription = this.familyHistoryForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                if (this.familyHistoryForm.dirty) {
                    this.assignFormValues();
                }
                if (this.familyHistoryForm.valid) {

                    const control = this.familyHistoryForm.get('ApplicationFamilyHistory.Submitted');

                    if (control && control.value) {
                        this.currentStatus.status = 'Submitted';
                        this.familyHistory.Submitted = true;
                    } else {
                        this.currentStatus.status = 'Complete';
                        this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                        this.familyHistory.Complete = true;
                        this.familyHistory.Submitted = false;
                    }
                } else {
                    this.currentStatus.status = 'In Progress';
                    this.familyHistory.Complete = false;
                }
                this.applicationService.currentSectionAndStatus.next(this.currentStatus);
            });
        }
    }

    getControls(): void {
        this.abstractFamilyHistoryControls = new ApplicationFamilyHistoryDynamicControls(null).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationFamilyHistory: this.fb.group({}),
        });
    }

    assignFormValues(): void {
        assignFormValues(this.familyHistory, this.familyHistoryForm.get('ApplicationFamilyHistory').value);
        this.manuallyAssignFormValues(this.familyHistory.MotherHistory, this.familyHistoryForm.get('MotherHistory.FamilyHistory'));
        this.manuallyAssignFormValues(this.familyHistory.FatherHistory, this.familyHistoryForm.get('FatherHistory.FamilyHistory'));
        this.manuallyAssignFormValues(
            this.familyHistory.MaternalGrandfatherHistory,
            this.familyHistoryForm.get('MaternalGrandfatherHistory.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.MaternalGrandmotherHistory,
            this.familyHistoryForm.get('MaternalGrandmotherHistory.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.PaternalGrandmotherHistory,
            this.familyHistoryForm.get('PaternalGrandmotherHistory.FamilyHistory'),
        );
        this.manuallyAssignFormValues(
            this.familyHistory.PaternalGrandfatherHistory,
            this.familyHistoryForm.get('PaternalGrandfatherHistory.FamilyHistory'),
        );
        if (this.hasSiblings) {
            this.assignSiblingFormValues();
        }
        safeDetectChanges(this.cdr);
    }

    assignHeightAndWeight(familyHistory: IFamilyHistory, formGroup: AbstractControl): void {
        const heightUnitControl = formGroup.get('SelectedHeightUnit');
        familyHistory.HeightUnitId = heightUnitControl ? heightUnitControl.value : null;
        if (familyHistory.HeightUnitId === UnitsOfMeasurementEnums.Meters) {
            familyHistory.Height = formGroup.get('Meters').value;
        } else if (familyHistory.HeightUnitId === UnitsOfMeasurementEnums.FeetAndInches) {
            familyHistory.Height = Number(formGroup.get('Feet').value * 12) + Number(formGroup.get('Inches').value);
        }
        const weightUnitControl = formGroup.get('SelectedWeightUnit');
        familyHistory.WeightUnitId = weightUnitControl ? weightUnitControl.value : null;
        familyHistory.Weight = formGroup.get('Weight').value;
    }

    manuallyAssignFormValues(familyHistory: IFamilyHistory, form: AbstractControl): void {
        this.assignHeightAndWeight(familyHistory, form);
        familyHistory.Deceased = form.get('Deceased').value === YesNo.Yes;
        familyHistory.Age = form.get('Age') ? form.get('Age').value : null;
        familyHistory.AgeAtDeath = form.get('AgeAtDeath') ? form.get('AgeAtDeath').value : null;
        familyHistory.Skills = form.get('Skills').value;
        familyHistory.Occupation = form.get('Occupation').value;
        familyHistory.EyeColorId = form.get('EyeColorId').value;
        familyHistory.HairColorId = form.get('HairColorId').value;
        familyHistory.HairTextureId = form.get('HairTextureId').value;
        familyHistory.SkinTypeId = form.get('SkinTypeId').value;
        familyHistory.RaceId = form.get('RaceId').value;
        familyHistory.EducationCompletedTypeId = form.get('EducationCompletedTypeId').value;
        familyHistory.CauseOfDeath = form.get('CauseOfDeath') ? form.get('CauseOfDeath').value : null;
        familyHistory.PlaceOfBirth =
            form.get('PlaceOfBirth') && form.get('PlaceOfBirth').value !== null ? this.getPlaceOfBirthCountryCode(form.get('PlaceOfBirth').value) : null;
    }

    getPlaceOfBirthCountryCode(value: any): string {
        return this.countries.find((c) => c.Id === value).CountryCode;
    }

    assignSiblingFormValues(): void {
        for (const form of this.siblingForms.controls) {
            const siblingIndex = +form.get('siblingIndex').value;
            this.manuallyAssignFormValues(this.familyHistory.SiblingFamilyHistories[siblingIndex], form.get('FamilyHistory'));
            this.familyHistory.SiblingFamilyHistories[siblingIndex].IsFullSibling =
                form.get('FamilyHistory.IsFullSibling').value === HalfSiblingTypes.Mother;
            const halfSiblingControl = form.get('FamilyHistory.HalfSiblingType');
            this.familyHistory.SiblingFamilyHistories[siblingIndex].IsHalfSiblingMother =
                halfSiblingControl && halfSiblingControl.value === HalfSiblingTypes.Mother;
            this.familyHistory.SiblingFamilyHistories[siblingIndex].IsHalfSiblingFather =
                halfSiblingControl && halfSiblingControl.value === HalfSiblingTypes.Father;
        }
    }

    getSiblingsControl(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: null,
            name: 'siblings',
            options: [
                { Id: YesNo.Yes, Name: 'Yes' },
                { Id: YesNo.No, Name: 'No' },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: {},
            value: this.familyHistory.SiblingFamilyHistories.length > 0 ? YesNo.Yes : YesNo.No,
        });
    }

    getHowManySiblingsControl(): DynamicField {
        return new DynamicField({
            formGroup: null,
            label: 'How Many?',
            name: 'howManySiblings',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(20), Validators.min(0)],
            validators: {},
            value: 0,
        });
    }

    showSiblingsControls(e): void {
        if (e === YesNo.No) {
            this.hasSiblings = false;
            this.familyHistory.SiblingFamilyHistories = [];
            this.siblingForms = this.fb.array([]);
            setTimeout(() => this.familyHistoryForm.markAsDirty());
        } else {
            this.hasSiblings = true;
        }
    }

    addSiblingsToFamilyHistory(): void {
        if (this.siblingsToAddNumber <= 0) {
            this.notificationService.error('Sibling count must be greater than 0');
            return;
        }
        for (let i = 0; i < this.siblingsToAddNumber; i++) {
            const siblingFamilyHistory = this.familyHistoryService.getEmptyFamilyHistory();
            siblingFamilyHistory.IsSibling = true;
            this.familyHistory.SiblingFamilyHistories.push(siblingFamilyHistory);
        }
        this.familyHistoryForm.markAsDirty();
    }

    removeSibling(index: number): void {
        this.familyHistoryForm.markAsDirty();
        this.familyHistory.SiblingFamilyHistories.splice(index, 1);
        this.siblingForms.controls = this.siblingForms.controls.filter((form) => form.get('siblingIndex').value !== index);
        // Update sibling index value on form so we remove the correct form when clicking remove
        for (let i = index; i < this.siblingForms.length; i++) {
            this.siblingForms.controls[i].patchValue({ siblingIndex: this.siblingForms.controls[i].value.siblingIndex - 1 });
        }
        safeDetectChanges(this.cdr);
    }
    get isComplete(): boolean {
        return this.familyHistory.Complete;
    }
}
