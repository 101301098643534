<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="medicalIssuesForm">
        <div class="input-scaffold" style="margin-top: 8px">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >For each of the following questions please select any options individuals in your family that have been diagnosed with the
                    preceding medical condition.</span
                >
            </label>
        </div>

        <!-- For each ApplicationMedicalIssue Create Component and pass in ApplicationMedicalIssue -->
        <div *ngFor="let type of medicalIssueTypes">
            <div class="form-section-border">
                <h3>{{ type.Name }}</h3>
            </div>
            <div *ngFor="let issue of getIssuesByTypeId(type.Id)">
                <issue-selector
                    [denied]="denied"
                    [applicationIssueMedicalIssue]="issue"
                    [medicalIssueRelationshipTypes]="medicalIssueRelationshipTypes"
                    (valueChange)="medicalIssueChanged()"
                    (subissueChange)="setSubissues($event)"
                >
                </issue-selector>
                <br />
            </div>
        </div>

        <!-- Text Has Std Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px">
            <label class="input-label">
                <span class="input-scaffold__label">Are you taking any medications?<span style="color: red"> *</span></span>
            </label>
        </div>
        <dynamic-field
            [field]="abstractApplicationIssueControls.SelectedIsTakingMedication"
            [form]="medicalIssuesForm"
            (valueChanges)="selectedIsTakingMedication = $event"
        ></dynamic-field>
        <div *ngIf="applicatantIsTakingMedications()" class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Which medications?<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationIssueControls.CurrentMedications" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <!-- Text Has Hiv Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px">
            <label class="input-label">
                <span class="input-scaffold__label">Have you had any surgeries in the past?<span style="color: red"> *</span></span>
            </label>
        </div>
        <dynamic-field
            [field]="abstractApplicationIssueControls.SelectedHasSurgeries"
            [form]="medicalIssuesForm"
            (valueChanges)="selectedHasSurgeries = $event"
        ></dynamic-field>
        <div *ngIf="applicatantHasSurgeries()" class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">List surgery and year<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationIssueControls.Surgeries" [form]="medicalIssuesForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Other Issues</span>
            </label>
            <dynamic-field [field]="abstractApplicationIssueControls.OtherIssues" [form]="medicalIssuesForm"></dynamic-field>
        </div>

        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div  class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                        accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractApplicationIssueControls.Submitted"
                    [form]="medicalIssuesForm"> </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>
