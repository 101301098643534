<ngx-skeleton-loader count="15" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="preliminaryHistoryForm">
        <!-- Form Controls -->

        <!-- Text Has Std Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px;">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >Have you tested positive for any STD’s in the past year? <span style="color: red;"> *</span></span
                >
            </label>
        </div>
        <dynamic-field
            [field]="abstractApplicationPreliminaryHistoryControls.SelectedHasStd"
            [form]="preliminaryHistoryForm"
            (valueChanges)="selectedHasStdId = $event"
        ></dynamic-field>
        <div *ngIf="applicatantHasStd()" class="input-scaffold">
            <dynamic-field [field]="abstractApplicationPreliminaryHistoryControls.StdName" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>
        <div *ngIf="applicatantHasStd()" class="input-scaffold">
            <dynamic-field [field]="abstractApplicationPreliminaryHistoryControls.DateOfStdTreatment" [form]="preliminaryHistoryForm"></dynamic-field>
        </div>

        <!-- Text Has Hiv Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px;">
            <label class="input-label">
                <span class="input-scaffold__label">Have you tested positive for HIV? <span style="color: red;"> *</span></span>
            </label>
        </div>
        <dynamic-field [field]="abstractApplicationPreliminaryHistoryControls.SelectedHasHiv" [form]="preliminaryHistoryForm"></dynamic-field>

        <!-- Text Has Is Adopted Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px;">
            <label class="input-label">
                <span class="input-scaffold__label">Are you adopted? <span style="color: red;"> *</span></span>
            </label>
        </div>
        <dynamic-field [field]="abstractApplicationPreliminaryHistoryControls.SelectedIsAdopted" [form]="preliminaryHistoryForm"></dynamic-field>
        <div class="adoption-tooltip" *ngIf="applicatantIsAdopted()">
            <div class="tooltiptext">
                {{ tooltipText }}
            </div>
        </div>

        <!-- Text Has Adopted Parents Selector - Used to Override Checkbox -->
        <div class="input-scaffold" style="margin-top: 8px;">
            <label class="input-label">
                <span class="input-scaffold__label">Are either of your parents adopted? <span style="color: red;"> *</span></span>
            </label>
        </div>
        <dynamic-field
            [field]="abstractApplicationPreliminaryHistoryControls.SelectedHasAdoptedParents"
            [form]="preliminaryHistoryForm"
            (valueChanges)="selectedHasAdoptedParentsId = $event"
        ></dynamic-field>
        <div class="adoption-tooltip" *ngIf="applicatantHasAdoptedParents()">
            <div class="tooltiptext">
                {{ tooltipText }}
            </div>
        </div>
        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div  class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                        accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractApplicationPreliminaryHistoryControls.Submitted"
                    [form]="preliminaryHistoryForm"> </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>
