import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReviewOutcomes } from '@model/enums/review-outcomes.enums';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { BehaviorSubject } from 'rxjs';
import { appPaths } from '../../../donor-portal/default-routes/app-paths.library';

export enum AppStates {
    notLoggedIn,
    applying,
    error,
    appointmentScheduled,
    appointmentNotScheduled,
    pending,
    denied,
    unverified,
}

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    appState = new BehaviorSubject<AppStates>(AppStates.notLoggedIn);

    constructor(private authService: AuthService, private router: Router) {}

    resolveAppState(): void {
        const user = this.authService.currentUser.getValue();
        if (!user.CustomOptions.EmailVerified) {
            this.setStateToUnverified();
        } else if (!user.CustomOptions.IsDonor) {
            this.setStateToApplying();
        } else if (user.CustomOptions.IsDonor) {
            this.resolveDonorStatus(user);
        }
    }

    setStateToUnverified(): void {
        this.appState.next(AppStates.unverified);
        this.router.navigate([appPaths.verifyUser]);
    }

    resolveDonorStatus(user: ILoggedIn): void {
        if (user.CustomOptions.DonorScore && user.CustomOptions.DonorScore >= 150) {
            if (user.CustomOptions.DonorScore >= 250) {
                this.setStateToDenied();
            } else {
                if (user.CustomOptions.MedicalReviewId === ReviewOutcomes.Pass) {
                    this.resolveAppointmentStatus(user);
                } else if (user.CustomOptions.MedicalReviewId === ReviewOutcomes.Fail) {
                    this.setStateToDenied();
                } else {
                    this.setStateToMedicalReview();
                }
            }
        } else {
            this.resolveAppointmentStatus(user);
        }
    }

    resolveAppointmentStatus(user: ILoggedIn): void {
        if (user.CustomOptions.AppointmentScheduled) {
            this.setStateToAppointmentScheduled();
        } else if (!user.CustomOptions.AppointmentScheduled) {
            this.setStateToAppointmentNotScheduled();
        }
    }

    setStateToNotLoggedIn(): void {
        this.appState.next(AppStates.notLoggedIn);
    }

    setStateToApplying(): void {
        this.appState.next(AppStates.applying);
        this.router.navigate([appPaths.application]);
    }

    setStateToError(): void {
        this.appState.next(AppStates.error);
    }

    setStateToDenied(): void {
        this.appState.next(AppStates.denied);
    }

    setStateToMedicalReview(): void {
        this.appState.next(AppStates.pending);
        // this.router.navigate(['/schedule']);
    }

    setStateToAppointmentScheduled(): void {
        this.appState.next(AppStates.appointmentScheduled);
        this.router.navigate(['account']);
    }

    setStateToAppointmentNotScheduled(): void {
        this.appState.next(AppStates.appointmentNotScheduled);
        // this.router.navigate(['/schedule']);
    }
}
