import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';

export const emptyApplicationPreliminaryHistory: IApplicationPreliminaryHistory = {
    Complete: null,
    Submitted: null,
    DateCreated: new Date(),
    DateOfStdTreatment: null,
    HasAdoptedParents: null,
    HasHiv: null,
    HasStd: null,
    Id: 0,
    IsAdopted: null,
    StdName: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationPreliminaryHistoryService extends BaseService<IApplicationPreliminaryHistory> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-preliminary-histories', http);
    }

    getEmptyApplicationPreliminaryHistory(): IApplicationPreliminaryHistory {
        return { ...emptyApplicationPreliminaryHistory };
    }
}
