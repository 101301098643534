<div [ngSwitch]="appState">
    <div class="header" *ngIf="appState !== appStates.notLoggedIn">
        <div class="header__wrapper">
            <div class="header__branding">
                <div>
                    <img src="./assets/logo-white.png" [attr.height]="isMobile ? 53 : 79" [attr.width]="isMobile ? 100 : 150" alt="tweb-logo" />
                </div>
                <a *ngIf="isMobile" class="hamburger" [ngClass]="{ active: mobileNavExpanded }" (click)="mobileNavExpanded = !mobileNavExpanded"
                    ><span></span
                ></a>
            </div>
            <nav *ngIf="(isMobile && mobileNavExpanded) || !isMobile" [ngClass]="{ header__navigation: !isMobile, mobile__navigation: isMobile }">
                <ul>
                    <li><a href="https://www.theworldeggandspermbank.com/" title="Back to Main Site">Return to Main Site</a></li>
                    <li><a (click)="logout(); mobileNavExpanded = false" [ngClass]="{ button: !isMobile }">Logout</a></li>
                </ul>
            </nav>
            <ng-progress></ng-progress>
        </div>
    </div>
    <!--- applying -->
    <div class="main-layout" *ngSwitchCase="appStates.applying">
        <ng-container *ngTemplateOutlet="applying"></ng-container>
    </div>
    <!-- appointment not scheduled -->
    <div class="main-layout" *ngSwitchCase="appStates.appointmentNotScheduled">
        <ng-container *ngTemplateOutlet="notscheduled"></ng-container>
    </div>
    <!-- appointment scheduled -->
    <div class="main-layout" *ngSwitchCase="appStates.appointmentScheduled">
        <ng-container *ngTemplateOutlet="scheduled"></ng-container>
    </div>
    <!-- pending review -->
    <div class="main-layout" *ngSwitchCase="appStates.pending">
        <ng-container *ngTemplateOutlet="pending"></ng-container>
    </div>
    <!-- denied -->
    <div class="main-layout" *ngSwitchCase="appStates.denied">
        <ng-container *ngTemplateOutlet="denied"></ng-container>
    </div>
    <!-- unverified -->
    <div class="main-layout" *ngSwitchCase="appStates.unverified">
        <router-outlet></router-outlet>
    </div>
    <!-- unauthenticated -->
    <div *ngSwitchCase="appStates.notLoggedIn">
        <ng-progress></ng-progress>
        <router-outlet></router-outlet>
    </div>
</div>

<ng-template #applying>
    <div class="main-layout__wrapper">
        <div class="main-layout__children">
            <router-outlet *ngIf="appState === appStates.applying"></router-outlet>
        </div>
    </div>
    <div class="footer">
        <span>Copyright &copy; 2020 The World Egg Bank, All Rights Reserved.</span>
    </div>
</ng-template>

<ng-template #notscheduled>
    <div class="main-layout__wrapper">
        <div class="center-horizontal-vertical text-center">
            <h2>Application Submitted Successfully</h2>
            <p>
                Congratulations, thank you for completing your donor application, our team is reviewing your application information and will reach
                out about next steps. You must schedule an interview appointment by clicking the button below to continue.
            </p>
            <p>
                <br />
                <appointment-scheduling
                  [allowInPerson]="allowInPersonAppointment"
                ></appointment-scheduling>
            </p>
        </div>
    </div>
    <div class="footer">
        <span>Copyright &copy; 2020 The World Egg Bank, All Rights Reserved.</span>
    </div>
</ng-template>

<ng-template #scheduled>
    <div class="main-layout__wrapper">
        <div class="main-layout__children">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="footer">
        <span>Copyright &copy; 2020 The World Egg Bank, All Rights Reserved.</span>
    </div>
</ng-template>

<ng-template #pending>
    <div class="main-layout__wrapper">
        <div class="center-horizontal-vertical text-center">
            <h2>Application Submitted Successfully</h2>
            <p>
                Thank you for completing your Donor Application. Please allow our team 3-4 days to review your application. We will reach out to you
                once your application has been reviewed.
            </p>
        </div>
    </div>
    <div class="footer">
        <span>Copyright &copy; 2020 The World Egg Bank, All Rights Reserved.</span>
    </div>
</ng-template>

<ng-template #denied>
    <div class="main-layout__wrapper">
        <div class="center-horizontal-vertical text-center">
            <h2>Application Submitted Successfully</h2>
            <p>
                Thank you for completing your donor application, unfortunately you do not meet the criteria to move forward with the donation process.
                If you have any questions about your application status please reach out to our administrative team.
                <br /><br />
                TWESB Phone/Email:<br />
                +1-877-331-2427<br />
                Arizona +1-602-678-1906<br />

                Donor@twesb.com
            </p>
        </div>
    </div>
    <div class="footer">
        <span>Copyright &copy; 2020 The World Egg Bank, All Rights Reserved.</span>
    </div>
</ng-template>
