import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';

import { AppRoutingModule } from './app-routing.module';
import { AppNavModule } from './nav/app-nav.module';
import { AppComponent } from './app.component';
import { environment } from './environments/environment';

import { ToastrModule } from 'ngx-toastr';

import { NotFoundComponent } from './default-routes/not-found/not-found.component';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegistrationComponent } from './registration/registration.component';
import { DonorLoginModule } from './login/donor-login.module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { DonorApplicationModule } from './donor-application/donor-application.module';
import { SharedModule } from './common/shared.module';
import { LoginConfigOverride } from './common/configs/login.config';
import { dynamicFormModuleConfig } from './common/configs/dynamic-form-override-config';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { DonorResetPasswordComponent } from './login/reset-password.component';
import { setGlobalInjector } from './global-injector';
import { DonorModule } from './donor-account/donor.module';
import { VerifyEmailModule } from './verify-email/verify-email.module';
import { ForgotUsernameComponent } from './login/forgot-username/forgot-username.component';
import { ForgotUsernameService } from './login/forgot-username/forgot-username.service';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        NotFoundComponent,
        RegistrationComponent,
        DonorResetPasswordComponent,
        ForgotPasswordComponent,
        ForgotUsernameComponent,
        UnsubscribeComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        AuthModule.forRoot(),
        EnvironmentModule.forRoot(environment),
        DonorApplicationModule.forRoot(),
        DonorModule,
        VerifyEmailModule,
        NotificationsModule,
        DonorLoginModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
        ForgotUsernameService,
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
