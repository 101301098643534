import { Validators } from '@angular/forms';
import { YesNo } from '@model/enums/yes-no.enum';
import {
    ApplicationPreliminaryHistoryDynamicControls,
    IApplicationPreliminaryHistoryDynamicControlsParameters,
} from '@model/form-controls/application-preliminary-history.form-controls';
import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';

export class ApplicationPreliminaryHistoryDynamicControlsPartial extends ApplicationPreliminaryHistoryDynamicControls {
    constructor(
        applicationpreliminaryhistoryPartial?: IApplicationPreliminaryHistory,
        additionalParameters?: IApplicationPreliminaryHistoryDynamicControlsParameters,
    ) {
        super(applicationpreliminaryhistoryPartial, additionalParameters);

        (<DynamicField>this.Form.DateOfStdTreatment).value = applicationpreliminaryhistoryPartial.DateOfStdTreatment;
        (<DynamicField>this.Form.DateOfStdTreatment).placeholder = 'MM/DD/YYYY';
        (<DynamicField>this.Form.DateOfStdTreatment).label = 'What date?';
        (<DynamicField>this.Form.DateOfStdTreatment).validators = { maxlength: 250, required: true };
        (<DynamicField>this.Form.StdName).label = 'Which disease?';

        (<DynamicField>this.Form.SelectedHasStd) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Has STD?',
            name: 'SelectedHasStd',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationpreliminaryhistoryPartial.HasStd === null ? null : applicationpreliminaryhistoryPartial.HasStd ? YesNo.Yes : YesNo.No,
        });

        (<DynamicField>this.Form.SelectedHasHiv) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Has HIV?',
            name: 'SelectedHasHiv',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationpreliminaryhistoryPartial.HasHiv === null ? null : applicationpreliminaryhistoryPartial.HasHiv ? YesNo.Yes : YesNo.No,
        });

        (<DynamicField>this.Form.SelectedIsAdopted) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Is Adopted?',
            name: 'SelectedIsAdopted',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationpreliminaryhistoryPartial.IsAdopted === null
                    ? null
                    : applicationpreliminaryhistoryPartial.IsAdopted
                    ? YesNo.Yes
                    : YesNo.No,
        });

        (<DynamicField>this.Form.SelectedHasAdoptedParents) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Has Adopted Parents?',
            name: 'SelectedHasAdoptedParents',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationpreliminaryhistoryPartial.HasAdoptedParents === null
                    ? null
                    : applicationpreliminaryhistoryPartial.HasAdoptedParents
                    ? YesNo.Yes
                    : 2,
        });
    }
}
