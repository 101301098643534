import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IApplication } from '@model/interfaces/application';
import { ApplicationStatuses } from '@model/enums/application-statuses.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { IApplicationValidationDTO } from '@model/interfaces/custom/application-validation-dto';
import { FormSections } from '@model/enums/form-sections.enum';
import { FormGroup } from '@angular/forms';
import { IPersonalInformationMetaData } from '@model/interfaces/custom/personal-information-meta-data';
import { IDonor } from '@model/interfaces/donor';
import { IApplicationImageDTO } from '@model/interfaces/custom/application-images-dto';

export const emptyApplication: IApplication = {
    ApplicationStatusId: ApplicationStatuses.PostRegistration,
    Archived: false,
    DateCreated: new Date(),
    DenialReasonId: null,
    DonorId: 0,
    Id: 0,
    UserId: 0,
};

export interface ICurrentSectionAndStatus {
    sectionId: FormSections;
    status: string;
    nextTab: string;
    nextTabLabel: string;
    formGroup: FormGroup;
    routerLink: string[];
}

@Injectable({
    providedIn: 'root',
})
export class ApplicationService extends BaseService<IApplication> {
    currentSectionAndStatus: BehaviorSubject<ICurrentSectionAndStatus> = new BehaviorSubject<ICurrentSectionAndStatus>({
        formGroup: null,
        nextTab: 'tab-preliminaryHistory',
        nextTabLabel: 'Preliminary History',
        routerLink: null,
        sectionId: FormSections.Basicinfo,
        status: '',
    });

    // used to trigger mark all fields as touched on form sections
    applicationSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    constructor(public http: HttpClient) {
        super('/donor-portal/application', http);
    }

    getEmptyApplication(): IApplication {
        return { ...emptyApplication };
    }

    getPendingApplication(): Observable<IApplication> {
        return this.http.get<IApplication>(`/api/v1/donor-portal/application/pending-application`);
    }

    validateForm(formSection: number): Observable<IApplicationValidationDTO> {
        return this.http.post<IApplicationValidationDTO>(`/donor-portal/application/validate-form/${formSection}`, {});
    }

    checkFormComplete(): Observable<IApplicationValidationDTO> {
        return this.http.post<IApplicationValidationDTO>(`/donor-portal/application/is-form-complete`, {});
    }

    submitApplication(application: IApplication, files: File[], ids: File[], ssn: string): Observable<IDonor> {
        let formData: FormData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i], files[i].name);
        }
        for (let i = 0; i < ids.length; i++) {
            formData.append('id', ids[i], ids[i].name);
        }
        formData.append('application', JSON.stringify(application));
        formData.append('socialSecurityNumber', ssn);
        return this.http.post<IDonor>(`/api/v1/donor-portal/application/finalize`, formData);
    }

    getPersonalInformationMetaData(): Observable<IPersonalInformationMetaData> {
        return this.http.get<IPersonalInformationMetaData>('/options/personal-information');
    }

    getAdminUploadedImages(applicationId: number): Observable<IApplicationImageDTO[]> {
        return this.http.get<IApplicationImageDTO[]>(`/api/v1/donor-portal/application/${applicationId}/admin-application-images`);
    }

    getAdminUploadedIdentityImages(applicationId: number): Observable<IApplicationImageDTO[]> {
        return this.http.get<IApplicationImageDTO[]>(`/api/v1/donor-portal/application/${applicationId}/admin-application-identity-images`);
    }

    scrollToFirstInvalidControl(elementRef: ElementRef): void {
        const firstInvalidControl: HTMLElement = elementRef.nativeElement.querySelector('form .ng-invalid');
        if (firstInvalidControl !== null) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: this.getTopOffset(firstInvalidControl),
            });
        }
    }

    scrollToControlId(elementRef: ElementRef, controlId: string): void {
        const control: HTMLElement = elementRef.nativeElement.querySelector('#' + controlId);
        if (control != null) {
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: this.getTopOffset(control),
            });
        }
    }

    getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
