import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IApplicationWhoAreYou } from '@model/interfaces/application-who-are-you';

export const emptyApplicationWhoAreYou: IApplicationWhoAreYou = {
    BirthControlTimespan: null,
    BirthControlTypeId: null,
    BodyStructureId: null,
    CollegeGpaId: null,
    CollegeMajor: null,
    Complete: null,
    Submitted: null,
    DateCreated: new Date(),
    EducationCompletedTypeId: null,
    ExerciseRoutine: null,
    EyeColorId: null,
    Favorites: null,
    Goals: null,
    HairTextureId: null,
    HasBeenPregnant: null,
    HasGivenBirth: null,
    HasPlasticSurgery: null,
    HasPreviouslyDonatedEggs: null,
    HighSchoolGpaId: null,
    HowManyLiveBirths: null,
    Id: 0,
    IsCurrentlyListedOnAnotherEggDonorRoster: null,
    NaturalHairColorId: null,
    NumberOfChildren: null,
    NumberOfEggDonations: null,
    OtherAncestryFather: null,
    OtherAncestryMother: null,
    OtherBirthControlMethod: null,
    OtherCommentsForTweb: null,
    OtherEggDonorRosters: null,
    PlasticSurgeryTypeAndYear: null,
    RaceId: null,
    SkinTypeId: null,
    TalentsAbilities: null,
    WhyInterestedInBecomingEggDonor: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationWhoAreYouService extends BaseService<IApplicationWhoAreYou> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-who-are-yous', http);
    }

    getEmptyApplicationWhoAreYou(): IApplicationWhoAreYou {
        return { ...emptyApplicationWhoAreYou };
    }
}
