import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';

export const emptyApplicationBasicInfo: IApplicationBasicInfo = {
    Address1: null,
    Address2: null,
    ApplicantPhoneNumber: null,
    City: null,
    Complete: false,
    Submitted: null,
    CountryCode: null,
    DateCreated: new Date(),
    Dob: null,
    HasTextCommunication: false,
    Height: null,
    HeightUnitId: null,
    Id: 0,
    Province: null,
    ReferralTypeId: null,
    Ssn: null,
    StateCode: null,
    Weight: null,
    WeightUnitId: null,
    Zip: null,
};

@Injectable({
    providedIn: 'root',
})
export class ApplicationBasicInfoService extends BaseService<IApplicationBasicInfo> {
    constructor(public http: HttpClient) {
        super('/donor-portal/application-basic-infos', http);
    }

    getEmptyApplicationBasicInfo(): IApplicationBasicInfo {
        return { ...emptyApplicationBasicInfo };
    }
}
