import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, SimpleChanges, ElementRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Subject, Subscription } from 'rxjs';

import { ILoggedIn } from '@mt-ng2/auth-module';
import { IReferralType } from '@model/interfaces/referral-type';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import { debounceTime } from 'rxjs/operators';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';
import { ReferralTypeService } from '@common/services/referral-type.service';
import { UnitsOfMeasurementService } from '@common/services/units-of-measurement.service';
import { ApplicationBasicInfoDynamicControlsPartial } from '@model/partials/application-basic-info-partial.form-controls';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { CommonService } from '../../../common/services/common.service';
import { ApplicationService, ICurrentSectionAndStatus } from '../../services/application.service';
import { safeDetectChanges } from '../../../common/safe-detect-changes.library';
import { FormSections } from '@model/enums/form-sections.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IGender } from  '@model/interfaces/gender';
import { GenderService } from '../../../../admin-portal/recipients/outcomes/service/gender.service';
import { IApplicationBasicInfoDynamicControlsParameters } from '@model/form-controls/application-basic-info.form-controls';

@Component({
    selector: 'basics-complete',
    templateUrl: './basics-complete.component.html',
})
export class BasicsCompleteComponent implements OnInit, OnDestroy {
    @Input() basicsComplete: IApplicationBasicInfo;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() isBasicInfoComplete: Subject<boolean>;
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();

    // abstract controls
    abstractApplicationBasicInfoControls: any;
    referralTypes: IReferralType[] = [];
    genders: IGender[] = [];

    unitsOfMeasurement: IUnitsOfMeasurement[];
    heightUnits: IUnitsOfMeasurement[];
    weightUnits: IUnitsOfMeasurement[];
    selectedHeightUnitId: number;
    selectedWeightUnitId: number;
    selectedTextCommunicationId: number;
    selectedCountryId: number;
    selectedReferralTypeId: number;
    usId: number;

    basicsCompleteForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    states: IStateMetaItem[] = [];
    countries: ICountryMetaItem[] = [];
    commentControl: AbstractControl;
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: 'tab-preliminaryHistory',
        nextTabLabel: 'Preliminary History',
        routerLink: null,
        sectionId: FormSections.Basicinfo,
        status: '',
    };

    subs = new Subscription();
    constructor(
        private fb: FormBuilder,
        private referralTypeService: ReferralTypeService,
        private unitsOfMeasurementService: UnitsOfMeasurementService,
        private commonService: CommonService,
        private applicationService: ApplicationService,
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef,
        private notificationsService: NotificationsService,
        private genderService: GenderService,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.basicsCompleteForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        forkJoin([
            this.commonService.getStates(),
            this.commonService.getCountries(),
            this.referralTypeService.getActive(),
            this.unitsOfMeasurementService.getItems(),
            this.genderService.getItems(),
        ]).subscribe((answer) => {
            const [states, countries, referralTypes, unitsOfMeasurement, genders ] = answer;
            this.states = this.commonService.getStateMetaItems();
            this.countries = this.commonService.getCountryMetaItems();
            this.usId = this.countries.find((country) => country.CountryCode === 'US').Id;
            this.referralTypes = referralTypes;
            this.genders = genders;
            this.selectedTextCommunicationId = this.basicsComplete.HasTextCommunication ? 1 : 2;
            this.selectedHeightUnitId = this.basicsComplete.HeightUnitId;
            this.selectedCountryId = this.basicsComplete.CountryCode
                ? this.countries.find((country) => country.CountryCode === this.basicsComplete.CountryCode).Id
                : null;
            this.selectedWeightUnitId = this.basicsComplete.WeightUnitId;
            this.selectedReferralTypeId = this.basicsComplete.ReferralTypeId;
            this.heightUnits = this.unitsOfMeasurementService.getHeightUnits();
            this.weightUnits = this.unitsOfMeasurementService.getWeightUnits();
            this.createForm();
            this.currentStatus.status = this.basicsComplete.Submitted ? 'Submitted' : (this.basicsComplete.Complete ? 'Complete' : 'In Progress');
            this.applicationService.currentSectionAndStatus.next(this.currentStatus);

            if (this.basicsComplete.Complete) {
                this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
            }
        });
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((save) => {
                if (save !== null && this.basicsCompleteForm) {
                    markAllFormFieldsAsTouched(this.basicsCompleteForm);
                }
            }),
        );
        this.isBasicInfoComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationsService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.basicsComplete.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationsService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.denied && this.basicsCompleteForm) {
            this.disableForm();
        }
    }

    disableForm(): void {
        setTimeout(() => this.basicsCompleteForm.disable(), 100);
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.basicsCompleteForm = this.assignFormGroups();
        this.formCreated = true;
        this.currentStatus.formGroup = this.basicsCompleteForm;
        if (this.denied) {
            setTimeout(() => {
                this.basicsCompleteForm.disable();
            });
        } else {
            this.subs.add(
                this.basicsCompleteForm.valueChanges.pipe(debounceTime(50)).subscribe(() => {
                    if (this.basicsCompleteForm.dirty) {
                        this.assignFormValues(this.basicsCompleteForm.value);
                    }

                    if (this.basicsCompleteForm.valid) {
                        const control = this.basicsCompleteForm.get('ApplicationBasicInfo.Submitted');

                        if (control && control.value) {
                            this.currentStatus.status = 'Submitted';
                            this.basicsComplete.Submitted = true;
                        } else {
                            this.currentStatus.status = 'Complete';
                            this.basicsComplete.Complete = true;
                            this.basicsComplete.Submitted = false;
                        }
                    } else {
                            this.currentStatus.status = 'In Progress';
                            this.basicsComplete.Complete = false;
                    }
                    this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                }),
            );
        }
    }

    stateControlCreated(e: FormControl): void {
        if (this.denied) {
            e.disable();
        }
        this.basicsCompleteForm.removeControl('Province');
        this.basicsCompleteForm.addControl('State', e);
    }

    provinceControlCreated(e: FormControl): void {
        if (this.denied) {
            e.disable();
        }
        this.basicsCompleteForm.removeControl('State');
        this.basicsCompleteForm.addControl('Province', e);
    }

    getControls(): void {
        const additionalParams: IApplicationBasicInfoDynamicControlsParameters = {
            heights: this.heightUnits,
            referralTypes: this.referralTypes,
            weights: this.weightUnits,
            genders: this.genders,
        };

        this.abstractApplicationBasicInfoControls = new ApplicationBasicInfoDynamicControlsPartial(
            this.states,
            this.countries,
            this.heightUnits,
            this.weightUnits,
            this.basicsComplete,
            additionalParams,
            null,
        ).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationBasicInfo: this.fb.group({}),
        });
    }

    assignFormValues(info: any): void {
        this.basicsComplete.Address1 = info.ApplicationBasicInfo.Address1;
        this.basicsComplete.Address2 = info.ApplicationBasicInfo.Address2;
        this.basicsComplete.City = info.ApplicationBasicInfo.City;
        this.basicsComplete.CountryCode =
            info.ApplicationBasicInfo.Country !== null
                ? this.countries.find((country) => country.Id === Number(info.ApplicationBasicInfo.Country)).CountryCode
                : null;
        this.basicsComplete.Province = info.Province;
        const state = this.states.find((state) => state.Id === Number(info.State));
        this.basicsComplete.StateCode = state ? state.StateCode : null;
        this.basicsComplete.Dob = info.ApplicationBasicInfo.Dob;
        this.basicsComplete.Zip = info.ApplicationBasicInfo.Zip;
        this.basicsComplete.HasTextCommunication = this.selectedTextCommunicationId === 1;
        this.basicsComplete.Height = this.calculateHeight(info.ApplicationBasicInfo);
        this.basicsComplete.HeightUnitId = this.selectedHeightUnitId;
        this.basicsComplete.ApplicantPhoneNumber = info.ApplicationBasicInfo.ApplicantPhoneNumber;
        this.basicsComplete.EmergencyContactName = info.ApplicationBasicInfo.EmergencyContactName;
        this.basicsComplete.EmergencyContactNumber = info.ApplicationBasicInfo.EmergencyContactNumber;
        this.basicsComplete.ReferralTypeId = info.ApplicationBasicInfo.ReferralTypeId;
        this.basicsComplete.Weight = info.ApplicationBasicInfo.Weight;
        this.basicsComplete.WeightUnitId = this.selectedWeightUnitId;
        this.basicsComplete.Comment = info.ApplicationBasicInfo.Comment || this.basicsComplete.Comment;
        this.basicsComplete.PlaceOfBirth = info.ApplicationBasicInfo.PlaceOfBirth !== null
            ? this.countries.find((c) => c.Id === info.ApplicationBasicInfo.PlaceOfBirth).CountryCode
            : null;
        // ** NOTE: The SSN must only be sent to an empty string in the basic info section
        // ** This is to bypass the initial ssn validation. Ref: BasicInformationCompleteValidator.cs ValidateSSN()
        this.basicsComplete.Ssn = '';

        this.basicsComplete.GenderId = info.ApplicationBasicInfo.GenderId;
    }

    // Calculates numeric height in inches if not using metric
    calculateHeight(info: any): number {
        return this.usingMetric() ? info.Meters : Number(info.Feet * 12) + Number(info.Inches);
    }

    setReferralType(event): void {
        const commentControl = this.basicsCompleteForm.get('ApplicationBasicInfo.Comment');
        if (commentControl) {
            commentControl.patchValue('');
            this.abstractApplicationBasicInfoControls.Comment.value = null;
        }
        this.selectedReferralTypeId = event;
        safeDetectChanges(this.cdr);
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    usingMetric(): boolean {
        return this.selectedHeightUnitId === UnitsOfMeasurementEnums.Meters;
    }

    addressIsInternational(): boolean {
        return this.selectedCountryId && this.selectedCountryId !== this.usId;
    }

    applicationHasComment(): boolean {
        return this.referralTypes
            .filter((referralType) => referralType.HasComments)
            .map((referralType) => referralType.Id)
            .includes(this.selectedReferralTypeId);
    }

    getCommentLabel(): string {
        return this.referralTypes.find((type) => type.Id === this.selectedReferralTypeId).Placeholder;
    }

    get isComplete(): boolean {
        return this.basicsComplete.Complete;
    }
}
