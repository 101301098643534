<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form class="miles-form padded row application-section" [formGroup]="basicsCompleteForm">
        <div class="form-section-border">
            <h3>Basic Info</h3>
        </div>
        <!-- Disabled controls used to display user presets uniformly -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">First Name</span>
                    <input [attr.type]="'text'" [attr.placeholder]="donor.CustomOptions.FirstName" disabled />
                </label>
            </div>
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Middle Initial</span>
                    <input [attr.type]="'text'"
                        [attr.placeholder]="donor.CustomOptions.MiddleInitial ? donor.CustomOptions.MiddleInitial : ''"
                        disabled />
                </label>
            </div>
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Last Name</span>
                    <input [attr.type]="'text'" [attr.placeholder]="donor.CustomOptions.LastName" disabled />
                </label>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Address</h3>
        </div>
        <!-- Form Controls -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Country" [form]="basicsCompleteForm"
                    (valueChanges)="selectedCountryId = $event"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.City" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
            <div *ngIf="!addressIsInternational()" class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.State"
                    (controlCreated)="stateControlCreated($event)"></dynamic-field>
            </div>
            <div *ngIf="addressIsInternational()" class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Province"
                    (controlCreated)="provinceControlCreated($event)"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Address1" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Address2" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Zip" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Communication</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.ApplicantPhoneNumber"
                    [form]="basicsCompleteForm"></dynamic-field>
            </div>
            <div class="input-scaffold" style="margin-top: 8px">
                <label class="input-label">
                    <span class="input-scaffold__label">Is it OK to text you?<span style="color: red"> *</span></span>
                    <dynamic-field [field]="abstractApplicationBasicInfoControls.SelectedTextCommunication"
                        [form]="basicsCompleteForm" (valueChanges)="selectedTextCommunicationId = $event">
                    </dynamic-field>
                </label>
            </div>
        </div>

        <!-- Text Has Communication Selector - Used to Override Checkbox -->

        <div class="form-section-border">
            <h3>Emergency Contact</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.EmergencyContactName"
                    [form]="basicsCompleteForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.EmergencyContactNumber"
                    [form]="basicsCompleteForm"></dynamic-field>
            </div>
        </div>


        <div class="form-section-border">
            <h3>Personal Info</h3>
        </div>
        <div class="input-scaffold">
            <span class="input-scaffold__label" [style.margin-bottom]="'-10px'">Date of Birth<span style="color: red">
                    *</span></span>
        </div>
        <dynamic-field [field]="abstractApplicationBasicInfoControls.Dob" [form]="basicsCompleteForm"></dynamic-field>
        <br />
        <!-- Height Unit Type Selector and Relevant Fields -->

        <div class="input-scaffold" style="width: inherit">
            <label class="input-label">
                <span class="input-scaffold__label">Height<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.SelectedHeightUnit" [form]="basicsCompleteForm"
                (valueChanges)="selectedHeightUnitId = $event"></dynamic-field>
        </div>
        <div *ngIf="!usingMetric()" class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Feet" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationBasicInfoControls.Inches" [form]="basicsCompleteForm">
                </dynamic-field>
            </div>
        </div>
        <div *ngIf="usingMetric()" class="input-scaffold">
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Meters" [form]="basicsCompleteForm">
            </dynamic-field>
        </div>

        <div class="input-scaffold" style="width: inherit">
            <dynamic-field [field]="abstractApplicationBasicInfoControls.PlaceOfBirth" [form]="basicsCompleteForm">
            </dynamic-field>
        </div>

        <!-- Weight Unit Type Selector and Relevant Fields -->
        <div class="input-scaffold" style="width: inherit">
            <label class="input-label">
                <span class="input-scaffold__label">Weight
                    <span style="color: red"> *</span>
                </span>
                <dynamic-field [field]="abstractApplicationBasicInfoControls.SelectedWeightUnit"
                    [form]="basicsCompleteForm" (valueChanges)="selectedWeightUnitId = $event"></dynamic-field>
            </label>
        </div>
        <div class="input-scaffold" style="width: inherit">
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Weight" [form]="basicsCompleteForm">
            </dynamic-field>
        </div>

        <div class="form-section-border">
            <h3>Referral</h3>
        </div>
        <!-- Referral type selector and relevant comment field if applicable -->
        <div class="input-scaffold" style="width: inherit">
            <dynamic-field [field]="abstractApplicationBasicInfoControls.ReferralTypeId" [form]="basicsCompleteForm"
                (valueChanges)="setReferralType($event)"></dynamic-field>
        </div>
        <div *ngIf="applicationHasComment()" class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">{{ getCommentLabel() }}<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.Comment" [form]="basicsCompleteForm">
            </dynamic-field>
        </div>

        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Gender<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationBasicInfoControls.GenderId" [form]="basicsCompleteForm">
            </dynamic-field>
        </div>

        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div  class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                        accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractApplicationBasicInfoControls.Submitted"
                    [form]="basicsCompleteForm"> </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>