import {
    ApplicationBasicInfoDynamicControls,
    IApplicationBasicInfoDynamicControlsParameters,
} from '@model/form-controls/application-basic-info.form-controls';
import { IApplicationBasicInfo } from '@model/interfaces/application-basic-info';
import { DynamicField, DynamicFieldTypes, DynamicFieldType, NumericInputTypes, InputTypes, LabelPositions, SelectInputTypes } from '@mt-ng2/dynamic-form';
import { IStateMetaItem } from '@model/interfaces/custom/state-meta-item';
import { ICountryMetaItem } from '@model/interfaces/custom/country-meta-item';
import { IUnitsOfMeasurement } from '@model/interfaces/units-of-measurement';
import { ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateParts } from '@mt-ng2/date-module';
import { AbstractControl } from '@angular/forms';
import { UnitsOfMeasurementEnums } from '@model/enums/units-of-measurement.enum';

export class ApplicationBasicInfoDynamicControlsPartial extends ApplicationBasicInfoDynamicControls {
    constructor(
        states?: IStateMetaItem[],
        countries?: ICountryMetaItem[],
        heightUnits?: IUnitsOfMeasurement[],
        weightUnits?: IUnitsOfMeasurement[],
        applicationbasicinfoPartial?: IApplicationBasicInfo,
        additionalParameters?: IApplicationBasicInfoDynamicControlsParameters,
        requireComment = true,
    ) {
        super(applicationbasicinfoPartial, additionalParameters);

        const genders = additionalParameters.genders;

        (<DynamicField>this.Form.Comment).label = '';
        if (requireComment) {
            (<DynamicField>this.Form.Comment).setRequired(true);
        }

        (<DynamicField>this.Form.ApplicantPhoneNumber).label = 'Phone Number';
        (<DynamicField>this.Form.ApplicantPhoneNumber).validators = { minlength: 10, required: true };
        (<DynamicField>this.Form.ApplicantPhoneNumber).validation = [Validators.minLength(10), Validators.required];
        (<DynamicField>this.Form.ApplicantPhoneNumber).labelPosition.position = LabelPositions.Hidden;

        (<DynamicField>this.Form.ReferralTypeId).setRequired(true);

        (<DynamicField>this.Form.Dob).type.datepickerOptions.minDate = { year: 1900, day: 1, month: 1 };
        (<DynamicField>this.Form.Dob).validation = [Validators.required, this.dobValidator()];
        (<DynamicField>this.Form.Dob).validators = { required: true };
        (<DynamicField>this.Form.Dob).insideBoxValidation = true;

        (<DynamicField>this.Form.PlaceOfBirth) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Place of birth',
            name: 'PlaceOfBirth',
            options: countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value:
                applicationbasicinfoPartial?.PlaceOfBirth && countries
                    ? countries.find((c) => c.CountryCode === applicationbasicinfoPartial?.PlaceOfBirth).Id
                    : null,
        });

        (<DynamicField>this.Form.Address1) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 1',
            name: 'Address1',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.Address1 || null,
        });

        (<DynamicField>this.Form.Address2) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Address 2',
            name: 'Address2',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: applicationbasicinfoPartial.Address2 || null,
        });

        (<DynamicField>this.Form.City) = new DynamicField({
            formGroup: this.formGroup,
            label: 'City',
            name: 'City',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.City || null,
        });

        (<DynamicField>this.Form.State) = new DynamicField({
            formGroup: this.formGroup,
            label: 'State',
            name: 'State',
            options: states,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.StateCode
                ? states.find((state) => state.StateCode === applicationbasicinfoPartial.StateCode).Id
                : null,
        });

        (<DynamicField>this.Form.Country) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Country',
            name: 'Country',
            options: countries,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.CountryCode
                ? countries.find((country) => country.CountryCode === applicationbasicinfoPartial.CountryCode).Id
                : countries.find((country) => country.CountryCode === 'US').Id,
        });

        (<DynamicField>this.Form.Province) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Province',
            name: 'Province',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.Province || null,
        });

        (<DynamicField>this.Form.Zip) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Zip',
            name: 'Zip',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.minLength(5), Validators.maxLength(20)],
            validators: { required: true, minlength: 5, maxlength: 20 },
            value: applicationbasicinfoPartial.Zip || null,
        });

        (<DynamicField>this.Form.Feet) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Feet',
            name: 'Feet',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: applicationbasicinfoPartial.Height ? Math.floor(applicationbasicinfoPartial.Height / 12) : null,
        });

        (<DynamicField>this.Form.Inches) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Inches',
            name: 'Inches',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(12)],
            validators: { required: true, max: 12 },
            value: applicationbasicinfoPartial.Height ? applicationbasicinfoPartial.Height % 12 : null,
        });

        (<DynamicField>this.Form.Meters) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Meters',
            name: 'Meters',
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Numeric,
                inputType: NumericInputTypes.Integer,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(10)],
            validators: { required: true, max: 10 },
            value: applicationbasicinfoPartial.Height ? applicationbasicinfoPartial.Height : null,
        });

        (<DynamicField>this.Form.SelectedHeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Height',
            name: 'SelectedHeightUnit',
            options: heightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.HeightUnitId ? applicationbasicinfoPartial.HeightUnitId : UnitsOfMeasurementEnums.FeetAndInches,
        });
        (<DynamicField>this.Form.GenderId) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Gender',
            name: 'GenderId',
            options:  genders,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.RadioButtonList,
                scale: null,
            }),
            validation: [Validators.required],
            validators: { required: true },
            value: applicationbasicinfoPartial.GenderId ? applicationbasicinfoPartial.GenderId : null,
        });
        (<DynamicField>this.Form.SelectedWeightUnit) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Weight',
            name: 'SelectedWeightUnit',
            options: weightUnits,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [Validators.required, Validators.max(500)],
            validators: { required: true, max: 500 },
            value: applicationbasicinfoPartial.WeightUnitId ? applicationbasicinfoPartial.WeightUnitId : UnitsOfMeasurementEnums.Pounds,
        });

        (<DynamicField>this.Form.SelectedTextCommunication) = new DynamicField({
            formGroup: this.formGroup,
            label: 'Is it OK to text you?',
            name: 'SelectedTextCommunication',
            options: [
                { Id: 1, Name: 'Yes', True: true },
                { Id: 2, Name: 'No', True: false },
            ],
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: null,
                scale: null,
            }),
            validation: [],
            validators: {},
            value: applicationbasicinfoPartial.HasTextCommunication === null ? null : applicationbasicinfoPartial.HasTextCommunication ? 1 : 2,
        });

        (<DynamicField>this.Form.Weight).type.fieldType = DynamicFieldTypes.Numeric;
        (<DynamicField>this.Form.Weight).type.inputType = NumericInputTypes.Integer;
        (<DynamicField>this.Form.Weight).placeholder = 'Weight';
        (<DynamicField>this.Form.Weight).setRequired(true);
 }

    dobValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const enteredDOB = new Date(control.value);
            const adultDOB = new Date().mtDate.subtract(18, DateParts.years);
            return adultDOB.date < enteredDOB ? { DOB: 'Must be 18 or older to apply' } : null;
        };
    }
}
