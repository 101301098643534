import { NgModule } from '@angular/core';
import { PathNotFoundResolveService } from '../default-routes/path-not-found-resolve.service';
import { DonorApplicationComponent } from './donor-application.component';
import { NotFoundComponent } from '../default-routes/not-found/not-found.component';
import { ClaimTypes } from '@model/ClaimTypes';
import { ClaimValues, IRoleGuarded } from '@mt-ng2/auth-module';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationSubmitComponent } from './sections/application-submit/application-submit.component';
import { CanSubmitGuard } from './submit.guard';
import { ApplicationGuard } from './application.guard';

const donorApplicationRouteGuard: IRoleGuarded = {
    claimType: ClaimTypes.DonorApplication,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
};

const applicationRoutes: Routes = [
    {
        canActivate: [ApplicationGuard],
        children: [{ path: '**', component: NotFoundComponent, data: donorApplicationRouteGuard, resolve: { path: PathNotFoundResolveService } }],
        component: DonorApplicationComponent,
        path: 'application',
        pathMatch: 'full',
    },
    {
        canActivate: [CanSubmitGuard],
        component: ApplicationSubmitComponent,
        path: `application/submit`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(applicationRoutes)],
    providers: [CanSubmitGuard, ApplicationGuard],
})
export class DonorApplicationRoutingModule {}
