import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, HostListener, SimpleChanges, OnChanges, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ILoggedIn } from '@mt-ng2/auth-module';
import { debounceTime } from 'rxjs/operators';
import { IApplicationPreliminaryHistory } from '@model/interfaces/application-preliminary-history';
import { ApplicationPreliminaryHistoryDynamicControlsPartial } from '@model/partials/application-preliminary-history-partial.form-controls';
import { ApplicationService, ICurrentSectionAndStatus } from '../../services/application.service';
import { FormSections } from '@model/enums/form-sections.enum';
import { YesNo } from '@model/enums/yes-no.enum';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';

@Component({
    selector: 'preliminary-history',
    styles: [
        `
            .adoption-tooltip .tooltiptext {
                color: red;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
            }
        `,
    ],
    templateUrl: './preliminary-history.component.html',
})
export class PreliminaryHistoryComponent implements OnInit, OnDestroy, OnChanges {
    @Input() preliminaryHistory: IApplicationPreliminaryHistory;
    @Input() donor: ILoggedIn;
    @Input() denied: boolean;
    @Input() isPreliminaryHistoryComplete: Subject<boolean>;
    @Output('validateForm') validateForm: EventEmitter<any> = new EventEmitter<any>();

    // abstract controls
    abstractApplicationPreliminaryHistoryControls: any;

    selectedHasStdId: number;
    selectedHasHivId: number;
    selectedIsAdoptedId: number;
    selectedHasAdoptedParentsId: number;

    tooltipText = 'You must know your family medical history in order to move forward with the donation process.';

    preliminaryHistoryForm: FormGroup;
    doubleClickIsDisabled = false;
    formCreated = false;
    formSubscription: Subscription;
    currentStatus: ICurrentSectionAndStatus = {
        formGroup: null,
        nextTab: 'tab-medicalIssues',
        nextTabLabel: 'Medical Issues',
        routerLink: null,
        sectionId: FormSections.PreliminaryHistory,
        status: '',
    };

    subs = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private notificationsService: NotificationsService,
        private applicationService: ApplicationService,
        private elementRef: ElementRef,
    ) {}

    @HostListener('window:beforeunload', ['$event'])
    warnOfUnsavedChanges(e): any {
        if (this.preliminaryHistoryForm.dirty && !this.denied) {
            e.returnValue = true;
            return false;
        }
    }

    ngOnInit(): void {
        this.selectedHasStdId = this.preliminaryHistory.HasStd ? 1 : 2;
        this.createForm();
        this.currentStatus.status = this.preliminaryHistory.Submitted ? 'Submitted' : (this.preliminaryHistory.Complete ? 'Complete' : 'In Progress');

        if (this.preliminaryHistory.Complete) {
            this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
        }

        this.applicationService.currentSectionAndStatus.next(this.currentStatus);
        this.subs.add(
            this.applicationService.applicationSaved$.subscribe((save) => {
                if (save !== null && this.preliminaryHistoryForm) {
                    markAllFormFieldsAsTouched(this.preliminaryHistoryForm);
                }
            }),
        );
        this.isPreliminaryHistoryComplete.subscribe((isComplete) => {
            if (!isComplete) {
                setTimeout(() => {
                    this.applicationService.scrollToFirstInvalidControl(this.elementRef);
                    this.notificationsService.error('You must fill out all required sections. Please scroll down to see what was missed.');
                }, 1000);
            } else {
                setTimeout(() => {
                    if (!this.preliminaryHistory.Submitted) {
                        this.applicationService.scrollToControlId(this.elementRef, 'Submitted');
                        this.notificationsService.success('The form is complete. Please review and confirm your entries.');
                    }
                }, 1000);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.denied && !changes.denied.isFirstChange()) {
            setTimeout(() => this.preliminaryHistoryForm.disable());
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    createForm(): void {
        this.getControls();
        this.preliminaryHistoryForm = this.assignFormGroups();
        this.formCreated = true;
        this.currentStatus.formGroup = this.preliminaryHistoryForm;
        if (this.denied) {
            this.disableForm();
        } else {
            this.subs.add(
                this.preliminaryHistoryForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                    if (this.preliminaryHistoryForm.dirty) {
                        this.assignFormValues(this.preliminaryHistoryForm.value.ApplicationPreliminaryHistory);
                    }

                    if (this.preliminaryHistoryForm.valid) {
                        const control = this.preliminaryHistoryForm.get('ApplicationPreliminaryHistory.Submitted');

                        if (control && control.value) {
                            this.currentStatus.status = 'Submitted';
                            this.preliminaryHistory.Submitted = true;
                            this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                        } else {

                            this.currentStatus.status = 'Complete';
                            this.preliminaryHistory.Complete = true;
                            this.preliminaryHistory.Submitted = false;
                        }

                    } else {
                        this.currentStatus.status = 'In Progress';
                        this.preliminaryHistory.Complete = false;
                    }
                    this.applicationService.currentSectionAndStatus.next(this.currentStatus);
                }),
            );
        }
    }

    disableForm(): void {
        setTimeout(() => this.preliminaryHistoryForm.disable());
    }

    getControls(): void {
        this.abstractApplicationPreliminaryHistoryControls = new ApplicationPreliminaryHistoryDynamicControlsPartial(this.preliminaryHistory, {
            formGroup: 'ApplicationPreliminaryHistory',
        }).Form;
    }

    assignFormGroups(): FormGroup {
        return this.fb.group({
            ApplicationPreliminaryHistory: this.fb.group({}),
        });
    }

    assignFormValues(info: any): void {
        this.preliminaryHistory.HasStd = info.SelectedHasStd !== null && info.SelectedHasStd !== undefined ? info.SelectedHasStd === YesNo.Yes : null;
        this.preliminaryHistory.StdName = info.StdName || this.preliminaryHistory.StdName;
        this.preliminaryHistory.DateOfStdTreatment = info.DateOfStdTreatment || this.preliminaryHistory.DateOfStdTreatment;
        this.preliminaryHistory.HasHiv = info.SelectedHasHiv !== null && info.SelectedHasHiv !== undefined ? info.SelectedHasHiv === YesNo.Yes : null;
        this.preliminaryHistory.IsAdopted =
            info.SelectedIsAdopted !== null && info.SelectedIsAdopted !== undefined ? info.SelectedIsAdopted === YesNo.Yes : null;
        this.preliminaryHistory.HasAdoptedParents =
            info.SelectedHasAdoptedParents !== null && info.SelectedHasAdoptedParents !== undefined
                ? info.SelectedHasAdoptedParents === YesNo.Yes
                : null;
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    cancelClick(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    applicatantHasStd(): boolean {
        return this.selectedHasStdId === YesNo.Yes;
    }

    applicatantIsAdopted(): boolean {
        return this.selectedIsAdoptedId === YesNo.Yes;
    }

    applicatantHasAdoptedParents(): boolean {
        return this.selectedHasAdoptedParentsId === YesNo.Yes;
    }

    get isComplete(): boolean {
        return this.preliminaryHistory.Complete;
    }

}
