<ngx-skeleton-loader count="25" *ngIf="!formCreated"></ngx-skeleton-loader>
<div *ngIf="formCreated">
    <form *ngIf="metaData" class="miles-form padded row application-section" [formGroup]="whoAreYouForm">
        <div class="form-section-border">
            <h3>Physical Features</h3>
        </div>
        <!-- Form Controls -->
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.BodyStructureId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.EyeColorId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.NaturalHairColorId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HairTextureId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.SkinTypeId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.RaceId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Ancestry Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" *ngIf="motherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Mother's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [ngClass]="{ invalid: !motherAncestrySelected && isSaved }"
                    [disabled]="denied"
                    [items]="motherAncestries"
                    placeholder="Select ancestries"
                    [filterMode]="false"
                    (onSelectionChanged)="motherAncestriesChanged($event)"
                    [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false"
                    [autoClose]="true"
                >
                </multiselect>
            </div>
            <div class="input-scaffold" *ngIf="fatherAncestries">
                <label class="input-label">
                    <span class="input-scaffold__label">Father's Side<span style="color: red"> *</span></span>
                </label>
                <multiselect
                    [ngClass]="{ invalid: !fatherAncestrySelected && isSaved }"
                    [disabled]="denied"
                    [items]="fatherAncestries"
                    placeholder="Select ancestries"
                    [filterMode]="false"
                    (onSelectionChanged)="fatherAncestriesChanged($event)"
                    [maxToShowInSelectedText]="'5'"
                    [showSelectAllButtons]="false"
                    [autoClose]="true"
                >
                </multiselect>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" *ngIf="getHasOtherAncestry('Mother') || whoAreYou.OtherAncestryMother">
                <br />
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherAncestryMother" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="getHasOtherAncestry('Father') || whoAreYou.OtherAncestryFather">
                <br />
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherAncestryFather" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Educational Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.EducationCompletedTypeId" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field 
                    *ngIf="highSchoolOrAbove()"
                    [field]="abstractApplicationWhoAreYouControls.HighSchoolGpaId" 
                    [form]="whoAreYouForm">
                </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field
                    *ngIf="collegeOrAbove()"
                    [field]="abstractApplicationWhoAreYouControls.CollegeMajor"
                    [form]="whoAreYouForm">
                </dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field
                *ngIf="collegeOrAbove()"
                [field]="abstractApplicationWhoAreYouControls.CollegeGpaId"
                    [form]="whoAreYouForm">
                </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group" style="max-width: 50%">
            <div class="input-scaffold">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.CurrentOccupation" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Introduce yourself</h3>
        </div>
        <div style="margin-bottom: 10px">This section is an opportunity to introduce yourself to potential recipients.</div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.TalentsAbilities" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.Favorites" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.CharacterDescription" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.WhyInterestedInBecomingEggDonor" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.Goals" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.ExerciseRoutine" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="form-section-border">
            <h3>Psychological Questionnaire</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <label class="input-label">
                    <span class="input-scaffold__label"
                        >Have you been the victim of a serious traumatic event?<span style="color: red"> *</span></span
                    >
                </label>
                <dynamic-field
                    [field]="abstractApplicationWhoAreYouControls.HasBeenVictimOfSeriousTraumaticEvent"
                    [form]="whoAreYouForm"
                ></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasBeenVictimOfSeriousTraumaticEvent')">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.SeriousTraumaticEventId" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >Have you or an immediate family member experienced or been diagnosed with serious depression lasting a month or more?<span
                        style="color: red"
                    >
                        *</span
                    ></span
                >
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasDepression" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >Have you or an immediate family member experienced or been diagnosed with severe mood swings ranging from blue to extraordinarily
                    happy?<span style="color: red"> *</span></span
                >
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasMoodSwings" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >Have you or an immediate family member experienced or been diagnosed with anxiety?<span style="color: red"> *</span></span
                >
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasAnxiety" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label"
                    >Have you or an immediate family member had thoughts of being watched or controlled, hearing voices, or having trouble knowing
                    what is real or not?<span style="color: red"> *</span></span
                >
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasSchizophreniaSigns" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Have you been under the care of a psychiatrist?<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasBeenUnderPsychiatristCare" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Have you ever received treatment for drug/alcohol abuse?<span style="color: red"> *</span></span>
            </label>
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasBeenInRehab" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 50%">
                <label class="input-label">
                    <span class="input-scaffold__label"
                        >Are there any known genetic conditions or birth defects in your family?<span style="color: red"> *</span></span
                    >
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasFamilyBirthDefects" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasFamilyBirthDefects')">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.FamilyBirthDefects" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="form-section-border">
            <h3>Other Information</h3>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 33%">
                <label class="input-label">
                    <span class="input-scaffold__label">Have you ever had plastic surgery?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasPlasticSurgery" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasPlasticSurgery')">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.PlasticSurgeryTypeAndYear" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold">
                <dynamic-field
                    [field]="abstractApplicationWhoAreYouControls.BirthControlTypeId"
                    [form]="whoAreYouForm"
                    (valueChanges)="onBirthControlTypeValueChange($event)"
                ></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="otherBirthControlSelected">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherBirthControlMethod" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold">
                <dynamic-field
                    *ngIf="showBirthControlTimespan"
                    [field]="abstractApplicationWhoAreYouControls.BirthControlTimespan"
                    [form]="whoAreYouForm"
                >
                </dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 40%">
                <label class="input-label">
                    <span class="input-scaffold__label">Have you ever given birth?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasGivenBirth" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasGivenBirth')">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.NumberOfChildren" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 40%">
                <label class="input-label">
                    <span class="input-scaffold__label">Have you ever been pregnant?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasBeenPregnant" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasBeenPregnant')">
                <label class="input-label">
                    <span class="input-scaffold__label">How many live births?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field
                    [field]="abstractApplicationWhoAreYouControls.HowManyLiveBirths"
                    [form]="whoAreYouForm"
                    (valueChanges)="onHowManyLiveBirthsValueChanges($event)"
                ></dynamic-field>
            </div>
        </div>

        <div style="width: 50%" formArrayName="ApplicationWhoAreYouChildrenArray">
            <ng-container *ngFor="let item of ApplicationWhoAreYouChildrenFormGroup; let i = index" [formGroupName]="i">
                <div style="padding: 10px; border-width: thin; border-style: solid">
                    <h3>Child Details - {{ i + 1 }}</h3>
                    <div class="input-scaffold">
                        <dynamic-field
                            [field]="item.value.YearOfChildBirth"
                            (valueChanges)="onYearOfChildBirthValueChange($event, i)"
                        ></dynamic-field>
                    </div>
                    <br />
                    <div class="input-scaffold">
                        <dynamic-field [field]="item.value.ChildGender" (valueChanges)="onChildGenderValueChange($event, i)"></dynamic-field>
                    </div>
                    <br />
                    <div class="input-scaffold" *ngIf="showIsBreastFeed[i]">
                        <label class="input-label">
                            <span class="input-scaffold__label">Are you currently breastfeeding?<span style="color: red"> *</span></span>
                        </label>
                        <dynamic-field [field]="item.value.IsBreastFeed" (valueChanges)="onIsBreastFeedValueChange($event, i)"></dynamic-field>
                    </div>
                </div>
                <br />
            </ng-container>
        </div>

        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 40%">
                <label class="input-label">
                    <span class="input-scaffold__label">Have you ever donated your eggs before?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.HasPreviouslyDonatedEggs" [form]="whoAreYouForm"></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('HasPreviouslyDonatedEggs')">
                <label class="input-label">
                    <span class="input-scaffold__label">How many times?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.NumberOfEggDonations" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold__group">
            <div class="input-scaffold" style="width: 40%">
                <label class="input-label">
                    <span class="input-scaffold__label">Are you currently listed on another egg donor roster?<span style="color: red"> *</span></span>
                </label>
                <dynamic-field
                    [field]="abstractApplicationWhoAreYouControls.IsCurrentlyListedOnAnotherEggDonorRoster"
                    [form]="whoAreYouForm"
                ></dynamic-field>
            </div>
            <div class="input-scaffold" *ngIf="optionIsSelected('IsCurrentlyListedOnAnotherEggDonorRoster')">
                <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherEggDonorRosters" [form]="whoAreYouForm"></dynamic-field>
            </div>
        </div>
        <div class="input-scaffold">
            <dynamic-field [field]="abstractApplicationWhoAreYouControls.OtherCommentsForTweb" [form]="whoAreYouForm"></dynamic-field>
        </div>
        <div *ngIf="isComplete">
            <div class="form-section-border">
                <h3>Review</h3>
            </div>
            <div>
                <div  class="input-scaffold__label" style="font-style: italic;">
                    Please review your answers, above, then confirm that the information is
                        accurate to the best of your knowledge.
                </div>
                <br />
                <dynamic-field id="Submitted" [field]="abstractApplicationWhoAreYouControls.Submitted"
                    [form]="whoAreYouForm"> </dynamic-field>
                <br />
            </div>
        </div>
    </form>
</div>
